import { URLVariables } from "../useURLVariables"
import { Rating } from "../../context/State"

const submitRating = (
  firebase: firebase.app.App,
  rating: Rating["value"],
  name: string,
  urlVariables: URLVariables
) => {
  return new Promise(async (resolve, reject) => {
    const addRating = firebase.functions().httpsCallable("addRating")

    if (!addRating) return reject('Cloud function "addRating" does not exist.')

    const timestamp = Date.now()
    addRating({
      key: urlVariables.key,
      project: urlVariables.project,
      rating: {
        value: rating,
        timestamp,
      },
      name: {
        value: name,
        timestamp,
      },
    })
      .then(result => {
        if (result.data.error) return reject(result.data.error)
        return resolve(true)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

export default submitRating
