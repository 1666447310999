import { URLVariables } from "../useURLVariables"

interface Response {
  project: {
    name: string
    backgroundColor: string
    image: string | undefined
  }
  vote: {
    title: string
    subtitle: string
  }
}

const getProjectInformation = async (
  firebase: firebase.app.App,
  variables: URLVariables
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    const getProjectInformation = firebase
      .functions()
      .httpsCallable("getProjectInformation")

    if (!getProjectInformation)
      return reject('Cloud function "getProjectInformation" does not exist.')

    getProjectInformation({
      project: variables.project,
      vote: variables.key,
    })
      .then(result => {
        if (result.data.error) return reject(result.data.error)

        return resolve(result.data.response)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

export default getProjectInformation
