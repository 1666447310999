import React from "react"
import { Result } from "antd"
import { WifiOutlined, LinkOutlined } from "@ant-design/icons"

interface Props {
  visible: boolean
  type:
    | "success"
    | "error"
    | "network-error"
    | "incorrect-vote-link"
    | "already-voted"
  error?: string
}

const VoteFeedback = ({ visible, type, error }: Props) => {
  if (visible) {
    if (type === "success") {
      return (
        <Result
          style={{ marginTop: 40 }}
          status="success"
          title="Thanks! Your vote has been counted."
          subTitle="You are free to close this window."
        />
      )
    } else if (type === "network-error" || type === "error") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          {type === "network-error" ? (
            <Result
              status="warning"
              icon={<WifiOutlined />}
              title={error}
              subTitle="Reconnect to the internet and then reload the page."
            />
          ) : (
            <Result
              status="error"
              title={error}
              subTitle="Please reload this window and try again."
            />
          )}
        </div>
      )
    } else if (type === "incorrect-vote-link") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Result
            status="warning"
            icon={<LinkOutlined />}
            title={error}
            subTitle="Contact the link provider to obtain a valid link."
          />
        </div>
      )
    } else if (type === "already-voted") {
      return (
        <Result
          style={{ marginTop: 40 }}
          status="error"
          title={error}
          subTitle="If you believe this to be an error, please contact the voting link provider."
        />
      )
    }
  }

  return <></>
}

export default VoteFeedback
