import React from "react"
import { Slider, InputNumber, Button } from "antd"
import { SliderValue } from "antd/lib/slider"
import "./VoteForm.scss"
import { CheckOutlined } from "@ant-design/icons"
import Search from "antd/lib/input/Search"

export interface RatingParameters {
  min: number
  max: number
  step: number
}

interface Props {
  parameters: RatingParameters
  rating: number
  submitting: boolean
  onSubmit: () => void
  onChangeNumber: (value: number) => void
  onChangeName: (value: string) => void
}

const getMarks = (min: number, max: number, step: number) => {
  let marks = {}
  for (let i = min; i <= max; i += 1) {
    let label
    if (step % 1 === 0) label = i
    else label = `${i}.0`
    marks = { ...marks, [i]: label }
  }
  return marks
}

const VoteForm = ({
  parameters,
  rating,
  submitting,
  onSubmit,
  onChangeNumber,
  onChangeName,
}: Props) => {
  const onChangeSlider = (value: SliderValue) => onChangeNumber(value as number)
  const onChangeNumberInput = (value: number | undefined) =>
    value ? onChangeNumber(value as number) : {}
  const onChangeNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeName(event.target.value)
  }

  return (
    <>
      <div className="vote-form">
        <div className="vote-form__input">
          <Slider
            className="vote-form__input__slider"
            defaultValue={parameters.min}
            min={parameters.min}
            max={parameters.max}
            step={parameters.step}
            value={rating}
            onChange={onChangeSlider}
            marks={getMarks(parameters.min, parameters.max, 1)}
          />
          <InputNumber
            className="vote-form__input__number"
            min={parameters.min}
            max={parameters.max}
            step={parameters.step}
            value={rating}
            onChange={onChangeNumberInput}
          />
        </div>
        <Search
          className="vote-form__submit"
          size="large"
          onChange={onChangeNameInput}
          onSearch={onSubmit}
          placeholder="Enter your name..."
          loading={submitting}
          enterButton={
            <Button
              loading={submitting}
              onClick={onSubmit}
              type="primary"
              placeholder="Your name"
              icon={<CheckOutlined />}
            ></Button>
          }
        ></Search>
        <p className="vote-form__submit__note">
          Don't worry: Your name is used to record that you've voted, but is not
          linked to your vote. Your vote is completely anonymous.
        </p>
      </div>
    </>
  )
}

export default VoteForm
