import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import "./Vote.scss"
import { message, notification } from "antd"
import useFirebase from "../../firebase/useFirebase"
import ProjectHeader from "../../components/project-header/ProjectHeader"
import SpinLoading from "../../components/loading/SpinLoading"
import VoteForm from "../../components/vote-form/VoteForm"
import VoteFeedback from "../../components/vote-feedback/VoteFeedback"
import useURLVariables from "../../helper/useURLVariables"
import getProjectInformation from "../../helper/vote/getProjectInformation"
import submitRating from "../../helper/vote/submitRating"
import { useCookies } from "react-cookie"

type VotingStages = "initial" | "loading" | "vote"

const voteParameters = {
  min: 1,
  max: 9,
  step: 1,
}

const Vote = () => {
  const firebase = useFirebase()
  const [ratingSubmitted, setRatingSubmitted] = useState(false)
  const [sendingRating, setSendingRating] = useState(false)
  const [project, setProject] = useState<{
    name: string
    backgroundColor: string
    image: string | undefined
  }>()
  const [vote, setVote] = useState<{
    title: string
    subtitle: string
  }>()
  const [rating, setRating] = useState(voteParameters.min)
  const [name, setName] = useState("")
  const [stage, setStage] = useState<VotingStages>("initial")
  const [useEffectRan, setUseEffectRan] = useState(false)
  const [getProjectError, setGetProjectError] = useState<null | string>(null)
  const [hasVotedBefore, setHasVotedBefore] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"])

  const urlVariables = useURLVariables()

  useEffect(() => {
    if (!useEffectRan) setUseEffectRan(true)

    if (urlVariables.key && urlVariables.project && stage === "initial") {
      setStage("loading")
    }

    if (stage === "loading" && project && vote) {
      setStage("vote")
    }
  }, [stage, urlVariables, project, vote])

  useEffect(() => {
    if (!firebase) return
    getProjectInformation(firebase, urlVariables)
      .then(information => {
        setProject(information.project)
        setVote(information.vote)
      })
      .catch(error => setGetProjectError(error))
  }, [firebase])

  useEffect(() => {
    console.log(cookies)
  }, [cookies])

  const onChangeNumber = (value: number) => {
    if (isNaN(value)) {
      return
    }
    setRating(value)
  }

  const onChangeName = (value: string) => setName(value)

  const onSubmit = () => {
    if (!firebase) return

    if (name === "") {
      message.error("Please input your name.")
      return
    }
    if (cookies[`vote-${urlVariables.key}`] === "true") {
      setHasVotedBefore(true)
      return
    }

    setSendingRating(true)
    submitRating(firebase, rating, name, urlVariables)
      .then(response => {
        setSendingRating(false)
        setRatingSubmitted(true)
        setCookie(`vote-${urlVariables.key}`, true)
      })
      .catch(error => {
        setSendingRating(false)
        console.error(error)
        notification["error"]({
          message: "There was an error submitting your response.",
          description:
            "If you aren't connected to the internet, reconnect and then try again. If that doesn't work, reload the page.",
        })
      })
  }

  return (
    <>
      <SEO title="Proposal Voting" />
      {/* ===== Initial Stage ===== */}
      {stage === "initial" ? (
        !urlVariables.key && !urlVariables.project && useEffectRan ? (
          <VoteFeedback
            visible={true}
            type="incorrect-vote-link"
            error="A voting link is required in order to vote."
          />
        ) : (!urlVariables.key || !urlVariables.project) && useEffectRan ? (
          <VoteFeedback
            visible={true}
            type="incorrect-vote-link"
            error="The voting link you have entered is incorrect."
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {/* ===== Loading Stage ===== */}
      {stage === "loading" ? (
        getProjectError ? (
          <VoteFeedback
            visible={getProjectError ? true : false}
            type="network-error"
            error={getProjectError}
          />
        ) : (
          <SpinLoading type="fullscreen" />
        )
      ) : (
        <></>
      )}

      {/* ===== Voting Stage ===== */}
      {stage === "vote" ? (
        <>
          <ProjectHeader
            fullWidth
            title={project ? project.name : undefined}
            backgroundColor={project ? project.backgroundColor : undefined}
            image={project && project.image ? project.image : undefined}
          />
          <>
            <div className="vote">
              <div className="vote__container">
                <h1 className="vote__proposal-name">{vote?.title}</h1>
                <h2 className="vote__author-name">{vote?.subtitle}</h2>

                {ratingSubmitted ? (
                  <VoteFeedback visible={ratingSubmitted} type="success" />
                ) : hasVotedBefore ? (
                  <VoteFeedback
                    visible={hasVotedBefore}
                    type="already-voted"
                    error="You cannot vote more than once on this proposal."
                  />
                ) : (
                  <VoteForm
                    parameters={voteParameters}
                    rating={rating}
                    submitting={sendingRating}
                    onSubmit={onSubmit}
                    onChangeNumber={onChangeNumber}
                    onChangeName={onChangeName}
                  />
                )}
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Vote
